import { FunctionComponent } from 'react';

import { BsFillCheckCircleFill } from 'react-icons/bs';
import { RiErrorWarningFill } from 'react-icons/ri';

export enum FeedbackType {
  POSITIVE,
  NEGATIVE,
}
interface FeedbackProps {
  type?: FeedbackType;
  text: string;
}
const FeedbackMessage: FunctionComponent<FeedbackProps> = ({
  text,
  type = FeedbackType.NEGATIVE,
}) => (
  <div className="relative overflow-visible mt-2 h-2">
    <span
      className={`text-xs font-bold px-2 py-0.5 absolute top-0 left-0 rounded-md flex items-center gap-2 ${
        type === FeedbackType.POSITIVE
          ? 'bg-green-100 text-green-600'
          : 'bg-red-100 text-red-600'
      } `}
    >
      {type === FeedbackType.POSITIVE ? (
        <BsFillCheckCircleFill size={20} />
      ) : (
        <RiErrorWarningFill size={20} />
      )}
      {text}
    </span>
  </div>
);

export default FeedbackMessage;
