import { HiArrowCircleRight, HiUserCircle } from 'react-icons/hi';

import Button from '@/tayara-kit/Button';
import FeedbackMessage from '@/tayara-kit/FeedbackMessage';
import Input from '@/tayara-kit/Input';
import Text from '@/tayara-kit/Text';

import LoginScaffold from './LoginScaffold';

export interface EmailScreenProps {
  identifiant: string;
  setIdentifiant: (value: string) => void;
  handleNextClick: () => void;
  error: string;
}

const EmailScreen = ({
  identifiant,
  setIdentifiant,
  handleNextClick,
  error,
}: EmailScreenProps) => (
  <LoginScaffold>
    {error ? <FeedbackMessage text={error} /> : <></>}
    <div className="w-full px-0">
      <Text
        text="Accéder à votre compte"
        as="h3"
        className="my-4 font-medium text-xl text-gray-700"
      />
      <Text
        text="Votre numéro de téléphone"
        as="label"
        className="text-sm text-gray-700 font-light"
      />
      <Input
        value={identifiant}
        onChange={(e) => setIdentifiant(e.target.value)}
        startIcon={HiUserCircle}
        name="username"
      />
    </div>
    {/* Actions */}
    <div className="card-actions grid grid-cols-12 gap-x-3">
      <Button
        aria-label="Suivant"
        className="btn-primary col-start-7 col-span-6 mt-6"
        icon={<HiArrowCircleRight size={20} />}
        onClick={handleNextClick}
        type="submit"
      >
        Suivant
      </Button>
    </div>
    <LoginScaffold.NewAccountFooter />
  </LoginScaffold>
);

export default EmailScreen;
