import { ChangeEventHandler, createRef, useEffect, useState } from 'react';

import { IconType } from 'react-icons/lib';
import { RiEyeCloseFill, RiEyeFill } from 'react-icons/ri';

interface InputProps extends React.HTMLAttributes<HTMLInputElement> {
  startIcon?: IconType;
  type?: string;
  placeholdler?: string;
  iconSize?: number;
  className?: string;
  value?: string;
  disabled?: boolean;
  checked?: boolean;
  name?: string;
  autofocus?: boolean;
  onChange?: ChangeEventHandler<HTMLInputElement>;
}

const Input = ({
  startIcon: Icon,
  type = 'text',
  placeholder = 'Écrire ici',
  iconSize = 22,
  className,
  autofocus,
  checked,
  ...props
}: InputProps) => {
  const inputRef = createRef<HTMLInputElement>();
  const [localType, setLocatType] = useState(type);
  const [passwordIsRevealed, setPasswordIsRevealed] = useState(false);

  const handlePasswordReveal = () => {
    setPasswordIsRevealed((prev) => !prev);
    if (
      localType.toLowerCase() === 'password' &&
      type.toLowerCase() === 'password'
    ) {
      setLocatType('text');
    } else {
      setLocatType('password');
    }
  };

  useEffect(
    () => (autofocus ? inputRef.current?.focus() : undefined),
    [autofocus, inputRef]
  );

  return (
    <div className="relative group">
      <input
        ref={inputRef}
        autoFocus={autofocus}
        {...props}
        type={localType}
        autoComplete="nope"
        checked={checked}
        placeholder={placeholder}
        className={`text-base input input-bordered w-full rounded-md bg-gray-50 focus:outline-none border-gray-300 pl-14 text-gray-700 font-bold ${className}`}
      />
      {/* Layer on top of the input */}
      <span className="flex justify-between items-center absolute inset-0 pointer-events-none px-2">
        {/* West side */}
        <span className="pl-3">
          {Icon && <Icon className="text-gray-600" size={iconSize} />}
        </span>

        {/* East side */}
        <span className="">
          {type === 'password' && (
            <label className="swap pointer-events-auto p-3 rounded-full hover:bg-primary/10 text-primary">
              <input
                type="checkbox"
                checked={passwordIsRevealed}
                onChange={handlePasswordReveal}
                className={`text-base`}
              />
              <div className="swap-on">
                <RiEyeFill />
              </div>
              <div className="swap-off">
                <RiEyeCloseFill />
              </div>
            </label>
          )}
        </span>
      </span>
    </div>
  );
};

export default Input;
