import Link from 'next/link';
import { useRouter } from 'next/router';
import { HiArrowNarrowLeft } from 'react-icons/hi';

import { rudderstackTrackEvent } from '@/api/rudderstack/client';
import Button from '@/tayara-kit/Button';
import Text from '@/tayara-kit/Text';

const LoginScaffold = ({
  children,
}: {
  children: JSX.Element | JSX.Element[];
}) => {
  const { back } = useRouter();

  return (
    <div className="w-full  bg-base-100 md:border md:border-gray-200 m-1 p-4 lg:p-8 rounded-lg relative lg:h-17">
      {/* Header actions  */}

      {/* Header */}
      <div className="flex flex-col justify-center items-center text-right lg:text-center mt-2">
        <div className="absolute left-4 top-0 w-full p-2 lg:p-8 mt-3 md:mt-1 flex justify-between">
          <button
            className="p-2 border border-gray-400 hover:border-gray-600 hover:bg-gray-100 rounded-md h-auto  lg:block"
            onClick={back}
          >
            <HiArrowNarrowLeft size={20} />
          </button>
        </div>
        <img
          className={`min-w-[70px] max-w-[120px] transition-all duration-300 xl:block`}
          src="/media/tayara-logo.svg"
          alt="Logo de tayara.tn"
        />

        <p className="text-center z-50 mx-auto text-xs mt-4 w-3/3 md:w-2/3 text-gray-500">
          En vous connectant vous accepter{' '}
          <Link
            className="z-50"
            href={{ pathname: 'https://www.tayara.tn/terms/' }}
            passHref
            target={'_blank'}
          >
            <span className="font-normal cursor-pointer">
              les termes et conditions
            </span>
          </Link>{' '}
          et la politique de confidentialité de Tayara.
        </p>
      </div>
      <div className="h-[1px] w-full bg-gray-300 my-6"></div>
      <form onSubmit={(e) => e.preventDefault()} className=" px-0">
        {children}
      </form>
    </div>
  );
};

const NewAccountFooter = ({ text }: { text?: string }) => {
  const { push } = useRouter();
  const track = () => {
    if (typeof window !== undefined) {
      rudderstackTrackEvent('Signup Selected');
      push({ pathname: '/signup' }, {}, { shallow: true });
    }
  };
  return (
    <>
      {/* Horizontal divider */}
      <div className="h-[1px] w-full bg-gray-300 my-6"></div>
      <div className="mx-0 text-center flex flex-col mt-4">
        {text ? (
          <Text
            className="text-xs mx-auto mt-2 text-gray-600 mb-4"
            text={text}
          />
        ) : (
          <Text
            className="text-xs mx-auto mt-2 text-gray-600 mb-4"
            text={`Vous êtes nouveau sur Tayara ? Créez votre compte rapidement et cherchez dans plus de 700K annonces en ligne.`}
          />
        )}
        <Button
          aria-label="Nouveau compte"
          light
          className="mx-auto px-12"
          onClick={() => track()}
        >
          Créer un nouveau compte
        </Button>
      </div>
    </>
  );
};

const LoginFooter = ({ text }: { text?: string }) => {
  const route = useRouter();
  const { redirect } = useRouter().query;
  async function navigate() {
    if (redirect && redirect !== '') {
      return route.replace({
        pathname: '/login',
        query: { redirect },
      });
    }
    return route.replace('/login');
  }
  return (
    <>
      {/* Horizontal divider */}
      <div className="h-[1px] w-full bg-gray-300 mt-7 mb-4"></div>
      <div className="mx-auto min-w-full text-center flex flex-col mt-4">
        {text ? (
          <Text
            className="text-2xs mx-auto mt-2 text-gray-600 mb-4"
            text={text}
          />
        ) : (
          <Text
            className="text-2xs mx-auto mt-2 text-gray-600 mb-4"
            text={`Vous avez déjà un compte sur Tayra ?`}
          />
        )}
        <Button
          aria-label="Se connecter"
          light
          className="mx-auto px-12"
          onClick={() => navigate()}
        >
          Accéder à votre compte
        </Button>
      </div>
    </>
  );
};

const ForgotPasswordFooter = ({
  clickForgetPassword,
}: {
  clickForgetPassword: () => void;
}) => (
  <>
    {/* Horizontal divider */}
    <div className="h-[1px] w-full bg-gray-300 mt-7 mb-4"></div>
    <div className="mx-0 text-center flex flex-col mt-4">
      {/* <Text
        className="text-2xs max-w-md mx-auto mt-2 text-gray-600 mb-4"
        text={`Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam.`}
      /> */}
      <Button
        aria-label="Mot de passe oublié"
        onClick={clickForgetPassword}
        className="mx-auto px-12 bg-gray-200 text-gray-700 hover:bg-gray-300"
      >
        Mot de passe oublié ?
      </Button>
    </div>
  </>
);

const AccessFooter = () => (
  <>
    {/* Horizontal divider */}
    <div className="h-[1px] w-full bg-gray-300 mt-7 mb-4"></div>
    <div className="mx-0 text-center flex flex-col mt-4">
      <Text
        className="text-2xs max-w-md mx-auto mt-2 text-gray-600 mb-4"
        text={``}
      />
    </div>
  </>
);

LoginScaffold.LoginFooter = LoginFooter;
LoginScaffold.NewAccountFooter = NewAccountFooter;
LoginScaffold.ForgotPasswordFooter = ForgotPasswordFooter;
LoginScaffold.AccessFooter = AccessFooter;

export default LoginScaffold;
